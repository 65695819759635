<template >
  <div>
    <van-nav-bar class="van_nav_bar" title="我的订单" :fixed="true" />
    <van-tabs
      class="van_tabs_view"
      offset-top="52"
      sticky
      line-width="20px"
      color="#00B05A"
      swipeable
      title-active-color="#00B05A"
      v-model="tabIndex"
      title-inactive-color="#333333"
    >
      <van-tab
        style="margin-bottom: 70px"
        v-for="(tagItem, tagIndex) in tabList"
        :title="tagItem.tagName"
        :key="tagIndex"
      >
        <div
          v-show="tagIndex === tagItem.orderIndex"
          v-for="(orderItem, oIndex) in tagItem.order"
          @click="toDetail(orderItem)"
          class="order_list_item_view"
          :key="oIndex"
        >
          <div class="status_view">
            <span>{{ orderItem.createTime }}</span>
            <span style="margin-right: 10px; font-size: 14px; color: #00b05a">
              {{
              orderType[orderItem.orderStatus]
              }}
            </span>
          </div>
          <div
            class="goods_info_view"
            v-for="(item, index) in (orderItem && orderItem.orderItemVos) || []"
            :key="index"
          >
            <img :src="imgApp + item.image" alt />
            <div class="info_view">
              <div class="top_info_view">
                <span class="name_view">{{ item.name }}</span>
                <div class="price_view">
                  <span>₱</span>
                  <span>{{ item.price }}</span>
                </div>
              </div>
              <span class="guige_view">{{ item.money }}</span>
              <span class="num_view">{{ item.num }}</span>
            </div>
          </div>
          <div class="total_view">
            <span class="total_num_view">共 {{ orderItem.totalNum }} 件商品 合计</span>
            <div>
              <span>₱</span>
              <!-- <span>{{ orderItem.totalMoney }}</span> -->
              <span>{{ orderItem.payMoney }}</span>
            </div>
          </div>
          <div class="btn">
            <van-button
              v-show="orderItem.orderStatus === 'NOPAY'"
              class="btn_view_cancel"
              round
              size="mini"
              @click.stop="cancel(orderItem.id)"
            >取消订单</van-button>
            <van-button
              v-show="
                orderItem.orderStatus === 'NOPAY' ||
                orderItem.orderStatus === 'NOSHIP' ||
                orderItem.orderStatus === 'NODELIVERY'
              "
              class="btn_view_modify"
              round
              size="mini"
              @click.stop="modifyTime(orderItem)"
            >修改配送时间</van-button>
            <van-button
              v-show="orderItem.orderStatus === 'NOPAY'"
              class="btn_view"
              round
              size="mini"
              color="linear-gradient(to right, #01B15B, #49D0A2)"
              @click.stop="payment(orderItem)"
            >支付</van-button>
            <van-button
              class="btn_view_cancel"
              size="mini"
              round
              v-show="
                orderItem.orderStatus === 'DELIVERYING' ||
                orderItem.orderStatus === 'DELIVERYED'
              "
            >查看物流</van-button>
            <van-button
              class="btn_view_modify"
              size="mini"
              round
              v-show="
                orderItem.orderStatus === 'DELIVERYING' ||
                orderItem.orderStatus === 'DELIVERYED'
              "
              @click.stop="confirmReceipt(orderItem.id)"
            >确认收货</van-button>
            <van-button
              v-show="orderItem.orderStatus === 'NOREACH'"
              class="btn_view_modify"
              round
              size="mini"
              @click.stop="again(orderItem.id)"
            >再次预约配送</van-button>
            <!-- <div class="daojishi_view" v-else-if="orderItem.isStatus === 2">
              <span>还剩</span>
              <van-count-down
                class="time_view"
                :time="time"
                format="HH 时 mm 分 ss 秒"
              />
              <span>取消订单</span>
            </div>-->

            <!-- <div
              v-show="
                orderItem.orderStatus === 'DELIVERYING' ||
                orderItem.orderStatus === 'DELIVERYED'
              "
              class="logistics_view"
            >
              <van-button class="btn_logistics_view" size="mini" round>查看物流</van-button>
              <van-button class="btn_confirm_view" size="mini" round>确认收货</van-button>
            </div>-->
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <!--支付-->
    <van-action-sheet class="van_action_sheet_view" v-model="show" title="请选择支付方式">
      <div class="popup_info_view">
        <span style="font-size: 20px; color: #3f3f3f; margin-top: 20px">实付金额</span>
        <label>
          <span>₱</span>
          <span>{{ totalMoney }}</span>
        </label>
        <van-radio-group class="group_view" v-model="payRadio" checked-color="#00B05A">
          <div class="radio_view" @click="isCheck(1)">
            <div>
              <img src="@/assets/alipay_bg.png" alt />
              <span>支付宝</span>
            </div>
            <van-radio class="radio_btn_view" :name="1"></van-radio>
          </div>
          <div class="radio_view" @click="isCheck(2)">
            <div>
              <img src="@/assets/wechat_pay_bg.png" alt />
              <span>微信</span>
            </div>
            <van-radio class="radio_btn_view" :name="2"></van-radio>
          </div>
          <div class="radio_view" @click="isCheck(3)">
            <div>
              <img src="@/assets/hdfk_bg.png" alt />
              <span>货到付款</span>
            </div>
            <van-radio class="radio_btn_view" :name="3"></van-radio>
          </div>
        </van-radio-group>
        <div class="btn_view">
          <van-button @click="selectPay">支付</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!--送达时间-->
    <van-action-sheet class="van_action_sheet_view" v-model="showTime" title="请选择预计送达时间">
      <div class="sdsj_view">
        <div class="sdsj_left_view">
          <div
            v-if="earliest == '今天'"
            :class="
              choiceTime === todayTime
                ? 'is_select_class_view'
                : 'is_no_select_class_view'
            "
            @click="selectTime(todayTime)"
          >{{ todayTime }}</div>
          <div
            v-if="earliest == '今天' || earliest == '明天'"
            :class="
              choiceTime === tomorrowTime
                ? 'is_select_class_view'
                : 'is_no_select_class_view'
            "
            @click="selectTime(tomorrowTime)"
          >{{ tomorrowTime }}</div>
          <div
            v-if="
              earliest == '今天' || earliest == '明天' || earliest == '后天'
            "
            :class="
              choiceTime === afterTime
                ? 'is_select_class_view'
                : 'is_no_select_class_view'
            "
            @click="selectTime(afterTime)"
          >{{ afterTime }}</div>
        </div>
        <div style="width: 65%; overflow: scroll">
          <div
            class="sdsj_right_view"
            v-for="(item, index) in list"
            :key="index"
            @click="handelDblclick(index)"
          >
            <van-radio-group class="group_view" v-model="radio" checked-color="#00B05A">
              <div
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                "
                @click="newIsCheck(index)"
              >
                <span
                  @click="isCheck(index)"
                  :class="
                    radio === index ? 'green_color_view' : 'balck_color_view'
                  "
                >{{ item }}</span>
                <van-radio
                  :class="
                    radio === index
                      ? 'radio_select_view'
                      : 'radio_no_select_view'
                  "
                  icon-size="20px"
                  style="margin-left: 20px"
                  :name="index"
                ></van-radio>
              </div>
            </van-radio-group>
          </div>
        </div>
      </div>
      <van-button @click="againTime">确定</van-button>
    </van-action-sheet>
    <van-dialog v-model="code_show" title="扫码支付" show-cancel-button @close="closeEvent">
      <img style="width: 100%" :src="wxCode" />
    </van-dialog>
    <webSocket ref="webSockt"></webSocket>
  </div>
</template>


<script>
import {
  ItemByOrderStatus,
  imgApp,
  order_page,
  reach_cash,
  pay_AliPayH5,
  pay_WxPayH5,
  AlipayWebH5,
  WxWebPayH5,
  order_cancelOrder, //取消订单
  confirmArrive, //确认收货
  getDeliveryTime, //计算时间
  updateDeliveryTime //修改配送时间
} from "@/service/api/index.js";
import webSocket from "@/components/webSocket";

export default {
  components: {
    webSocket
  },
  data() {
    return {
      imgApp,
      orderType: {
        ALL: "全部",
        NOPAY: "待支付",
        NOSHIP: "待配送",
        NODELIVERY: "待配送",
        NOACCEPT: "待配送",
        DELIVERYING: "配送中",
        DELIVERYED: "配送中",
        COMPLETE: "已完成",
        CANCLE: "已取消",
        NOREACH: "未送达",
        0: "ALL",
        1: "NOPAY",
        2: "NODELIVERY",
        3: "DELIVERYING",
        4: "COMPLETE",
        5: "CANCLE",
        6: "NOREACH"
        // 全部订单
        // ALL(0),
        //  * 待付款
        // NOPAY(1),
        //  * 待派单
        // NOSHIP(2),
        //  * 待配送
        // NODELIVERY(3),
        //  * 配送中
        // DELIVERYING(4),
        //  * 配送员已送达
        // DELIVERYED(13),
        //  * 已完成
        // COMPLETE(5),
        //  * 已取消
        // CANCLE(6),
        //  * 未送达
        // NOREACH(7),
      },
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight,
      tabIndex: 0,
      time: 30 * 60 * 60 * 1000,
      tabList: [
        {
          tid: 101,
          orderIndex: 0,
          tagName: "全部",
          order: []
        },
        {
          tid: 102,
          tagName: "待支付",
          orderIndex: 1,
          order: []
        },
        {
          tid: 103,
          tagName: "待配送",
          orderIndex: 2,
          order: []
        },
        {
          tid: 104,
          tagName: "配送中",
          orderIndex: 3,
          order: []
        },
        {
          tid: 105,
          tagName: "已完成",
          orderIndex: 4,
          order: []
        },
        {
          tid: 106,
          tagName: "已取消",
          orderIndex: 5,
          order: []
        },
        {
          tid: 107,
          tagName: "未送达",
          orderIndex: 6,
          order: []
        }
      ],
      show: false,
      radio: "",
      payRadio: "",
      totalMoney: "",
      type: "",
      sn: "",
      userAgent: "",
      code_show: false,
      wxCode: "",
      orderId: "",
      showTime: false,
      todayTime: "",
      tomorrowTime: "",
      afterTime: "",
      list: [],
      choiceTime: "",
      allTime: [],
      earliestTime: "",
      radio: "",
      i: ""
    };
  },
  watch: {
    choiceTime: {
      handler(active) {
        console.log(active)
        // console.log(this.allTime)
        // return
        for (let i in this.allTime) {
          if (this.allTime[i]) {
            // console.log(active,this.allTime[i].date)
            if (active == this.allTime[i].date) {
              // console.log(this.allTime[i].time)
              this.list = this.allTime[i].time;
            }
          }
        }
      }
    },
    tabIndex: {
      handler(n) {
        this.type = n;
        // console.log(n);
        // console.log(n, "n");
        // order_page({ orderStatus: "ALL", pageIndex: 1, pageSize: 999 }).then(
        //   (res) => {}
        // );
        this.ItemByOrderStatus(n);
        // console.log("ceshi",this.tabList[0].order);
        // console.log("ggg", this.orderType["CANCLE"]);

        // 全部订单
        // ALL(0),
        //  * 待付款
        // NOPAY(1),
        //  * 待派单
        // NOSHIP(2),
        //  * 待配送
        // NODELIVERY(3),
        //  * 配送中
        // DELIVERYING(4),
        //  * 配送员已送达
        // DELIVERYED(13),
        //  * 已完成
        // COMPLETE(5),
        //  * 已取消
        // CANCLE(6),
        //  * 未送达
        // NOREACH(7),
        // for (let i in this.tabList[0].order) {
        //   console.log(this.tabList[0].order[i].orderStatus);
        // }
        // console.log(this.tabList[0].order)
      },
      immediate: true
    }
  },
  created() {
    this.getDate();
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.userAgent = "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.userAgent = "这是Android";
    } else {
      this.userAgent = "PC";
    }
  },
  mounted() {
    this.tabIndex = this.$route.params.tabIndex;
  },
  computed: {
    earliest: {
      get() {
        if (this.todayTime == this.earliestTime) {
          return "今天";
        }
        if (this.tomorrowTime == this.earliestTime) {
          return "明天";
        }
        if (this.afterTime == this.earliestTime) {
          return "后天";
        }
      }
    },
    isTimeSelectTitle: {
      get() {
        if (this.todayTime == this.choiceTime) {
          return "今天";
        }
        if (this.tomorrowTime == this.choiceTime) {
          return "明天";
        }
        if (this.afterTime == this.choiceTime) {
          return "后天";
        }
      }
    },
    time_: {
      get() {
        if (this.list[this.radio || 0]) {
          return this.list[this.radio || 0];
        }
        return "";
      }
    }
  },
  methods: {
    getDate() {
      var dateTime = new Date();
      const y = dateTime.getFullYear();
      const m = (dateTime.getMonth() + 1 + "").padStart(2, "0");
      const d = (dateTime.getDate() + "").padStart(2, "0");
      this.todayTime = y + "-" + m + "-" + d;
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      const y2 = dateTime.getFullYear();
      const m2 = (dateTime.getMonth() + 1 + "").padStart(2, "0");
      const d2 = (dateTime.getDate() + "").padStart(2, "0");
      var youWant = y2 + "-" + m2 + "-" + d2;
      this.tomorrowTime = youWant;
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      const y3 = dateTime.getFullYear();
      const m3 = (dateTime.getMonth() + 1 + "").padStart(2, "0");
      const d3 = (dateTime.getDate() + "").padStart(2, "0");
      var youWant1 = y3 + "-" + m3 + "-" + d3;
      this.afterTime = youWant1;
    },
    toDetail(e) {
      this.$router.push({ name: "OrderDetail", query: { id: e.id } });
    },
    ItemByOrderStatus(n) {
      if (n != undefined) {
        ItemByOrderStatus({
          orderStatus: this.orderType[n],
          pageIndex: 1,
          pageSize: 999
        }).then(res => {
          if (res.status === 200) {
            this.tabList[n].order = res.data.orders;
          }
        });
      }
    },
    isCheck(e) {
      if (e === 1) {
        this.payRadio = 1;
        this.payType = "ALIPAY";
      } else if (e === 2) {
        this.payRadio = 2;
        this.payType = "WXPAY";
      } else if (e === 3) {
        this.payRadio = 3;
        this.payType = "HUO";
      }
    },
    selectTime(val) {
      this.choiceTime = val;
    },
    newIsCheck(e) {
      this.radio = e;
    },
    handelDblclick(e) {
      this.i++;
      setTimeout(() => {
        this.i = 0;
      }, 500);
      if (this.i > 1) {
        this.showTime = false;
        this.i = 0;
      }
    },
    //重新选择时间提交
    againTime() {
      var time = this.choiceTime;
      var data = {
        id: this.orderId,
        time: ""
      };
      const n = parseInt(this.time_.split("-")[0]);
      if (n % 2 !== 0) {
        data.time = (time + " " + (n + 1 + "").padStart(2, "0") + ":00").trim();
      } else {
        data.time = (time + " " + this.time_.split("-")[0]).trim();
      }
      updateDeliveryTime(data).then(res => {
          this.ItemByOrderStatus(this.type);
          this.ItemByOrderStatus(0);
          this.showTime = false;
      });
    },
    //取消订单
    cancel(id) {
      order_cancelOrder({ orderId: id }).then(res => {
        this.ItemByOrderStatus(this.type);
        this.ItemByOrderStatus(0);
      });
    },
    //支付
    payment(data) {
      // console.log(data)
      // return
      this.sn = data.orderSn;
      this.totalMoney = data.totalMoney;
      this.orderId = data.id;
      this.show = true;
    },
    //确认收货
    confirmReceipt(id) {
      confirmArrive({ id: id }).then(res => {
        if (res.status == 200) {
          this.ItemByOrderStatus(this.type);
          this.ItemByOrderStatus(0);
        }
      });
    },
    // 修改时间
    modifyTime(data) {
      // console.log(data)
      //获取时间区间
      getDeliveryTime({
        data: [
          { id: data.orderItemVos[0].skuId, num: data.orderItemVos[0].num }
        ],
        params: { deliveryTime: encodeURIComponent(data.deliveryTime) }
      }).then(res => {
        this.allTime = res.data;
        if (
          this.allTime.dayAfterTomorrow != null &&
          this.allTime.tomorrow != null &&
          this.allTime.today != null
        ) {
          this.earliestTime = this.allTime.today.date;
          this.choiceTime = this.allTime.today.date;
        } else if (
          this.allTime.dayAfterTomorrow != null &&
          this.allTime.tomorrow != null
        ) {
          this.earliestTime = this.allTime.tomorrow.date;
          this.choiceTime = this.allTime.tomorrow.date;
        } else if (this.allTime.dayAfterTomorrow != null) {
          this.earliestTime = this.allTime.dayAfterTomorrow.date;
          this.choiceTime = this.allTime.dayAfterTomorrow.date;
        }
      });
      this.orderId = data.id;
      this.showTime = true;
    },
    //再次预约配送
    again(id) {
      this.$router.push({
        path: "/againOrder",
        query: {
          id: id
        }
      });
    },
    selectPay() {
      if (this.payType == "ALIPAY" && this.userAgent == "PC") {
        AlipayWebH5({
          sn: this.sn,
          notifyUrl: `${window.location.protocol}//${window.location.host}/successOrder`
        }).then(res => {
          localStorage.setItem("dom", res.data);
          this.$router.push("zf");
        });
      } else if (this.payType === "ALIPAY") {
        pay_AliPayH5({
          sn: this.sn,
          returnUrl: `${window.location.protocol}//${window.location.host}/successOrder`,
          quitUrl: `${window.location.protocol}//${window.location.host}/home`
        }).then(res => {
          if (res.status == 200) {
            localStorage.setItem("dom", res.data);
            this.$router.push("zf");
          } else {
            return Toast(res.message);
          }
        });
      } else if (this.payType == "WXPAY" && this.userAgent == "PC") {
        WxWebPayH5({
          sn: this.sn
        }).then(res => {
          this.wxCode = res.data;
          this.code_show = true;
          this.$refs["webSockt"].webSocketLink();
        });
      } else if (this.payType === "WXPAY") {
        pay_WxPayH5({
          sn: this.sn
        }).then(res => {
          window.location.href =
            res.data +
            `&redirect_url=` +
            encodeURIComponent(
              `${window.location.protocol}//${window.location.host}/successOrder`
            );
        });
      } else if (this.payType === "HUO") {
        const formData = new FormData();
        formData.append("orderId", this.orderId);
        reach_cash(formData).then(res => {
          if (res.status == 200) {
            this.$router.push({
              path: "/successOrder",
              query: {
                id: this.$route.query.id
              }
            });
          } else {
            Toast(res.message);
          }
        });
      }
    },
    closeEvent() {
      this.$refs["webSockt"].onClose();
    }
  }
};
</script>

<style lang="less">
@media screen and (min-width: 768px) {
  .van_nav_bar {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .van_action_sheet_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.sdsj_view {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  .sdsj_left_view {
    width: 35%;
    background: #f1f5f6;
    display: flex;
    flex-direction: column;
    .is_select_class_view {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #00b05a;
      justify-content: center;
      background: white;
    }
    .is_no_select_class_view {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #333333;
      border-radius: 0px 8px 0px 0px;
      justify-content: center;
      background: #f1f5f6;
    }
  }
  .sdsj_right_view {
    width: 65%;
    height: 50px;
    font-size: 14;
    padding-left: 30px;
    color: #00b05a;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
  }
}
.van-tabs__nav {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-user-select: none;
  max-width: 525px;
  margin-left: 50%;
  width: 100%;
  transform: translateX(-50%);
  user-select: none;
}

.van_tabs_view {
  position: absolute;
  top: 44px;
  width: 100%;
  max-width: 540px;
  background: #f1f5f6;
}
.order_list_item_view {
  margin: 10px 8px 10px 8px;
  background: white;
  border-radius: 10px;
  .status_view {
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span:first-of-type {
      margin-left: 10px;
      color: #acacac;
      font-size: 14px;
    }
  }
  .goods_info_view {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    img {
      width: 84px;
      height: 84px;
      border-radius: 8px;
      margin-right: 8px;
      margin-left: 10px;
    }
    .info_view {
      width: 100%;
      display: flex;
      flex-direction: column;
      .top_info_view {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .name_view {
          display: block;
          color: #3f3f3f;
          font-size: 11px;
        }
        .price_view {
          span:first-of-type {
            font-size: 12px;
            color: #3f3f3f;
          }
          span:last-of-type {
            font-size: 15px;
            color: #3f3f3f;
            margin-right: 10px;
          }
        }
      }
      .guige_view {
        font-size: 11px;
        color: #999999;
        margin-top: 5px;
      }
      .num_view {
        font-size: 14px;
        color: #3f3f3f;
        margin-top: 18px;
      }
    }
  }
  .total_view {
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    .total_num_view {
      font-size: 14px;
      color: #3f3f3f;
      margin-right: 6px;
    }
    div {
      margin-right: 12px;
      span:first-of-type {
        color: #df3436;
        font-size: 12px;
      }
      span:last-of-type {
        color: #df3436;
        font-size: 18px;
      }
    }
  }
  .btn {
    padding: 9px 12px;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
  .btn_view {
    padding: 0px 20px;
    height: 28px;
  }
  .btn_view_modify {
    padding: 0px 20px;
    height: 28px;
    color: #01b15b;
  }
  .btn_view_cancel {
    padding: 0px 20px;
    height: 28px;
  }
  .daojishi_view {
    width: 200px;
    height: 28px;
    border: 0.67px solid #cacacc;
    border-radius: 18px;
    position: absolute;
    right: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .time_view {
      font-size: 14px;
      color: #e02020;
    }
    span {
      color: #3f3f3f;
      font-size: 12px;
      margin-top: 1px;
    }
  }
  .logistics_view {
    position: absolute;
    right: 12px;
    margin-bottom: 5px;
    .btn_logistics_view,
    .btn_confirm_view {
      width: 88px;
      height: 28px;
      font-size: 12px;
      text-align: center;
      line-height: 28px;
      color: #3f3f3f;
    }
    .btn_logistics_view {
      margin-right: 16px;
    }
  }
}
.van-tab__pane {
  background: #f1f5f6;
}
.popup_info_view {
  width: 100%;
  height: 337px;
  background: white;
  display: flex;
  text-align: center;
  border-top: 0.5px solid #eeeeee;
  flex-direction: column;
  label {
    margin-top: 11px;
    margin-bottom: 40px;
    span:first-of-type {
      font-size: 25px;
      color: #df3436;
    }
    span:last-of-type {
      font-size: 37px;
      color: #df3436;
    }
  }
  .van_action_sheet_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .radio_view {
    width: 100%;
    height: 46px;
    display: flex;
    background: white;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 12px;
      img {
        width: 34px;
        height: 34px;
      }
      span {
        display: block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3f3f3f;
        margin-left: 17px;
      }
    }
    .radio_btn_view {
      margin-right: 12px;
    }
  }
}
.van_action_sheet_view {
  .btn_view {
    max-width: 540px;
    width: 100%;
    height: 57px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      height: 36px;
      margin-left: 20px;
      margin-right: 20px;
      background: linear-gradient(to right, #01b15b, #49d0a2);
      border-radius: 18px;
      border: none;
      color: white;
      font-size: 13px;
    }
  }
  button {
    width: 100%;
    height: 36px;
    background: linear-gradient(to right, #01b15b, #49d0a2);
    border-radius: 18px;
    border: none;
    color: white;
    font-size: 13px;
  }
}
.balck_color_view {
  font-size: 14px;
  color: #3f3f3f;
  display: block;
}
.green_color_view {
  font-size: 14px;
  display: block;
  color: #00b05a;
}
</style>
