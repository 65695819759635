<template>
  <div class="cart">
    <van-nav-bar :fixed="true" class="nav_bar_view">
      <template #title>
        <span style="color: black; font-size: 18px">购物车</span>
      </template>
      <template #right style="display: flex; flex-direction: row; align-items: center">
        <span style="font-size: 14px; color: #00b05a" @click="toPage">删除</span>
      </template>
    </van-nav-bar>
    <div style="margin-top: 50px;padding-bottom: 140px;">
      <!-- 当日达 -->
      <div class="st-spacing-main" v-if="saleList.length > 0">
        <div class="st-item product-item">
          <div style="margin-top: 10px" class="st-border-bottom store-title">
            <div
              @click="checkShop"
              style="
                width: 100%;
                height: 46px;
                background: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #eee;
              "
            >
              <van-checkbox
                style="margin-left: 12px"
                icon-size="15px"
                v-model="checked"
                checked-color="#00B05A"
              >
                <span style="font-size: 14px; color: #3f3f3f">当日送达</span>
              </van-checkbox>
            </div>
          </div>
          <ul v-for="(item, index) in saleList" :key="item.id" @click="check(index)">
            <van-swipe-cell>
              <li
                style="
                  width: 100%;
                  height: 120px;
                  background: white;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                "
              >
                <van-checkbox
                  style="margin-left: 12px"
                  icon-size="15px"
                  v-model="item.isChecked"
                  @change="checkChange(item)"
                  checked-color="#00B05A"
                ></van-checkbox>
                <div
                  style="
                    flex: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-left: 5px;
                  "
                >
                  <img
                    style="width: 74px; height: 74px; margin-right: 13px"
                    :src="imgApp + item.spu.image"
                    alt
                  />
                  <div style="display: flex; flex-direction: column; width: 75%">
                    <span style="font-size: 13px; color: #3f3f3f">
                      {{
                      item.spu.name
                      }}
                    </span>
                    <div
                      style="
                        width: fit-content;
                        height: 20px;
                        background: rgba(0, 176, 90, 0.09);
                        text-align: center;
                        line-height: 20px;
                        margin-top: 8px;
                        padding: 0 4px;
                      "
                    >
                      <span
                        style="font-size: 12px; color: #00b05a; z-index: 1"
                        v-for="(el, i) in item.specs"
                        :key="i"
                      >{{ el.content }}</span>
                      <van-icon name="arrow-down" color="#00B05A" />
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-top: 8px">
                        <span style="font-size: 12px; color: #df3436">₱</span>
                        <span style="font-size: 18px; color: #df3436">
                          {{
                          item.sku.quote.price
                          }}
                        </span>
                      </div>
                      <div @click.stop>
                        <van-stepper
                          v-model="item.quantity"
                          @change="stepperChange(item)"
                          theme="round"
                          button-size="22"
                          style="margin-right: 15px"
                          disable-input
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <template #right>
                <van-button
                  style="height: 100%"
                  square
                  text="删除"
                  type="danger"
                  @click.stop="deleteOne(item, index)"
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>

            <!-- <li> -->
            <!-- 这是商品组件 -->
            <!-- <product-cart size="mini" type="number" :option="3"></product-cart> -->
            <!-- </li> -->
          </ul>
        </div>
      </div>
      <!-- 明日达 -->
      <div class="st-spacing-main" v-if="tomorrowSaleList.length > 0">
        <div class="st-item product-item">
          <div style="margin-top: 10px" class="st-border-bottom store-title">
            <div
              @click="checkShopTomorrow"
              style="
                width: 100%;
                height: 46px;
                background: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #eee;
              "
            >
              <van-checkbox
                style="margin-left: 12px"
                icon-size="15px"
                v-model="tomorrowChecked"
                checked-color="#00B05A"
              >
                <span style="font-size: 14px; color: #3f3f3f">明日送达</span>
              </van-checkbox>
            </div>
          </div>
          <ul v-for="(item, index) in tomorrowSaleList" :key="item.id">
            <van-swipe-cell>
              <li
                style="
                  width: 100%;
                  height: 120px;
                  background: white;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                "
              >
                <van-checkbox
                  style="margin-left: 12px"
                  icon-size="15px"
                  v-model="item.isChecked"
                  @change="tomorrowCheckChange(item)"
                  checked-color="#00B05A"
                ></van-checkbox>
                <div
                  style="
                    flex: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-left: 5px;
                  "
                >
                  <img
                    style="width: 74px; height: 74px; margin-right: 13px"
                    :src="imgApp + item.spu.image"
                    alt
                  />
                  <div style="display: flex; flex-direction: column; width: 75%">
                    <span style="font-size: 13px; color: #3f3f3f">
                      {{
                      item.spu.name
                      }}
                    </span>
                    <div
                      style="
                        width: fit-content;
                        height: 20px;
                        background: rgba(0, 176, 90, 0.09);
                        text-align: center;
                        line-height: 20px;
                        margin-top: 8px;
                        padding: 0 4px;
                      "
                    >
                      <span
                        style="font-size: 12px; color: #00b05a; z-index: 1"
                        v-for="(el, i) in item.specs"
                        :key="i"
                      >{{ el.content }}</span>
                      <van-icon name="arrow-down" color="#00B05A" />
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-top: 8px">
                        <span style="font-size: 12px; color: #df3436">₱</span>
                        <span style="font-size: 18px; color: #df3436">
                          {{
                          item.sku.quote.price
                          }}
                        </span>
                      </div>
                      <div>
                        <van-stepper
                          v-model="item.quantity"
                          @change="stepperChange(item)"
                          theme="round"
                          button-size="22"
                          style="margin-right: 15px"
                          disable-input
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <template #right>
                <van-button
                  style="height: 100%"
                  square
                  text="删除"
                  type="danger"
                  @click.stop="deleteOne(item, index)"
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>

            <!-- <li> -->
            <!-- 这是商品组件 -->
            <!-- <product-cart size="mini" type="number" :option="3"></product-cart> -->
            <!-- </li> -->
          </ul>
        </div>
      </div>
      <div v-if="efficacyList.length > 0">
        <div class="shixiao_view">
          <div
            style="
                width: 100%;
                height: 46px;
                background: white;
                line-height: 46px;
                margin-top: 10px;
                border: 1px solid #eee;
              "
          >
            <span style="margin-left: 12px; font-size: 14px; color: #3f3f3f">已失效</span>
          </div>
          <van-swipe-cell v-for="(item, i) in efficacyList" :key="i">
            <!-- 前循环在这 -->
            <div class="sx_img_view">
              <div class="img_view">
                <img :src="imgApp + item.spu.image" alt />
                <img src="../../assets/shixiao_bg.png" alt />
              </div>
              <div style="width: 100%; display: flex; flex-direction: column">
                <span style="font-size: 13px; color: #3f3f3f">
                  {{
                  item.spu.name
                  }}
                </span>
                <div
                  style="
                      width: fit-content;
                      height: 20px;
                      background: rgba(0, 176, 90, 0.09);
                      text-align: center;
                      line-height: 20px;
                      margin-top: 8px;
                      padding: 0 4px;
                    "
                >
                  <span
                    style="font-size: 12px; color: #00b05a; z-index: 1"
                    v-for="(el, i) in item.specs"
                    :key="i"
                  >{{ el.content }}</span>
                  <van-icon name="arrow-down" color="#00B05A" />
                </div>
                <div
                  style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;
                    "
                >
                  <div style="margin-top: 8px">
                    <span style="font-size: 12px; color: #df3436">₱</span>
                    <span style="font-size: 18px; color: #df3436">
                      {{
                      item.spu.price
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <van-button
                style="height: 100%"
                square
                text="删除"
                type="danger"
                @click.stop="deleteOne(item, index)"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
          <!-- <template #right>
              <van-button style="height: 100%" square text="删除" type="danger" class="delete-button" />
          </template>-->
        </div>
        <!-- <template #right>
            <van-button style="height: 100%" square text="删除" type="danger" class="delete-button" />
        </template>-->
      </div>
      <!-- 售罄 -->
      <div class="shixiao_view" style="margin-bottom: 10px" v-if="stockList.length > 0">
        <div class="st-item product-item">
          <div style="margin-top: 10px" class="st-border-bottom store-title">
            <div
              style="
                padding-left: 12px;
                font-size: 14px;
                color: #3f3f3f;
                font-weight: bold;
                width: 100%;
                height: 46px;
                background: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #eee;
              "
            >
              <span style="font-size: 14px; color: #3f3f3f">已售罄</span>
            </div>
          </div>
          <ul v-for="(item, index) in stockList" :key="item.id">
            <van-swipe-cell>
              <li
                style="
                  width: 100%;
                  height: 120px;
                  background: white;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                "
              >
                <div class="sx_img_view">
                  <!-- style="
                    flex: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-left: 5px;
                  "-->
                  <div class="img_view">
                    <img :src="imgApp + item.spu.image" alt />
                    <img src="../../assets/shoujin_bg.png" alt />
                  </div>
                  <div style="display: flex; flex-direction: column; width: 75%">
                    <span style="font-size: 13px; color: #3f3f3f">
                      {{
                      item.spu.name
                      }}
                    </span>
                    <div
                      style="
                        width: fit-content;
                        height: 20px;
                        background: rgba(0, 176, 90, 0.09);
                        text-align: center;
                        line-height: 20px;
                        margin-top: 8px;
                        padding: 0 4px;
                      "
                    >
                      <span
                        style="font-size: 12px; color: #00b05a; z-index: 1"
                        v-for="(el, i) in item.specs"
                        :key="i"
                      >{{ el.content }}</span>
                      <van-icon name="arrow-down" color="#00B05A" />
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div style="margin-top: 8px">
                        <span style="font-size: 12px; color: #df3436">₱</span>
                        <span style="font-size: 18px; color: #df3436">
                          {{
                          item.spu.price
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <template #right>
                <van-button
                  style="height: 100%"
                  square
                  text="删除"
                  type="danger"
                  @click.stop="deleteOne(item, index)"
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>

            <!-- <li> -->
            <!-- 这是商品组件 -->
            <!-- <product-cart size="mini" type="number" :option="3"></product-cart> -->
            <!-- </li> -->
          </ul>
        </div>
      </div>
    
    </div>
    <van-dialog
      v-model="show"
      :show-cancel-button="true"
      @confirm="allDelete"
      style="font-size: 15px"
      width="310px"
      message="确定删除所选商品？"
      cancel-button-text="再想想"
      cancel-button-color="#6D7278"
      confirm-button-text="删除"
      confirm-button-color="#DF3436"
    ></van-dialog>
    <div class="bottom_view">
      <van-checkbox
        class="checkbox_view"
        icon-size="15px"
        v-model="isCheckAll"
        @change="checkAll()"
        checked-color="#00B05A"
      >全选</van-checkbox>
      <div class="bottom_right_view">
        <span>不含运费</span>
        <span>合计</span>
        <span>₱</span>
        <span>{{ count }}</span>
        <div :class="ispay ? 'jiesuan_view2' : 'jiesuan_view'" @click="pay">去结算</div>
      </div>
    </div>
    <!-- <van-submit-bar class="settlement" :price="10060" button-text="去结算" @submit="onSubmit" button-color="#ACACAC">
            <span class="cart-freight" slot="default">不含运费</span>
    </van-submit-bar>-->
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  imgApp,
  cartGet,
  deleteOne,
  getBusiness,
  cartUpdate
} from "@/service/api/index.js";
import { accAdd, accMul } from "@/utils/auth";

export default {
  data() {
    return {
      checked: false,
      tomorrowChecked: false,
      imgApp,
      value: 1,
      show: false,
      list: [],
      cartIds: "",
      cartInfoList: [],
      isCheckAll: false, // 是否全选
      allPrice: 0, // 所有价格
      allShops: 0, // 被选中的商店数量
      allCount: 0, // 被选中的产品数量
      startTime: 0,
      endTime: 0
    };
  },
  computed: {
    // 当日送达
    saleList() {
      // console.log(this.list.filter(item => item.sendType === 0));
      console.log("this.list",this.list);
      // console.log(JSON.stringify(this.list.filter((item) => item.sendType === 0)[0]))
      return this.list.filter(item => item.sendType === 0);
    },
    // 明日送达
    tomorrowSaleList() {
      return this.list.filter(item => item.sendType === 1);
    },
    // 已售罄
    stockList() {
      return this.list.filter(item => item.sendType === 2);
    },
    // 已失效
    efficacyList() {
      return this.list.filter(item => item.status === "DISABLE");
    },
    count() {
      let sum = 0;
      sum = this.list.reduce((total, item) => {
        if (item.isChecked) {
          //如果sendType不是已售罄 并且 status不是已失效 才累加
          if (item.sendType != 2 && item.status != "DISABLE") {
            return accAdd(total, accMul(item.sku.quote.price, item.quantity));
          }
        } else {
          return total;
        }
      }, 0);
      // console.log("sum", sum);
      return sum;
    },
    ispay() {
      let index = this.list.findIndex(_ => _.isChecked == true);
      if (index != -1) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.cartData();
    getBusiness({}).then(res => {
      if (res.status == 200) {
        this.startTime = parseInt(res.data.startTime);
        this.endTime = parseInt(res.data.endTime);
      }
    });
  },
  methods: {
    pay() {
      if (this.ispay) {
        // let arr = this.list.filter((item) => item.isChecked);
        let arr = [];
        const saleList = this.saleList.filter(item => item.isChecked);
        const tomorrowSaleList = this.tomorrowSaleList.filter(
          item => item.isChecked
        );
        arr.push(...saleList, ...tomorrowSaleList);
        if (arr.length == 0) {
          // Toast.fail("请选择商品");
          return;
        }
        let idList = [],
          indexList = [];

        arr.forEach((item, index) => {
          idList.push(item.id);
          indexList.push(index);
        });
        this.cartIds = idList.join();
        this.$router.push({
          path: "/confirmOrder",
          query: {
            cartIds: this.cartIds,
            page: "cart"
          }
        });
      } else {
        Toast.fail("请先选择商品");
      }
    },
    check(index) {
      for (let i in this.saleList) {
        if (index == i) {
          if (this.saleList[i].isChecked == undefined) {
            this.$set(this.saleList[i], "isChecked", true);
          } else {
            this.$set(
              this.saleList[i],
              "isChecked",
              !this.saleList[i].isChecked
            );
          }
        }
      }
    },
    checkChange() {
      this.checked = this.saleList.every(el => el.isChecked);
    },
    tomorrowCheckChange() {
      this.tomorrowChecked = this.tomorrowSaleList.every(el => el.isChecked);
    },
    allDelete() {
      let arr = this.list.filter(item => item.isChecked);
      if (arr.length == 0) {
        Toast.fail("请选择商品");
        return;
      }
      let idList = [],
        indexList = [];

      arr.forEach((item, index) => {
        idList.push(item.id);
        indexList.push(index);
      });
      deleteOne({ cartIds: idList.join(",") }).then(res => {
        if (res.status == 200) {
          idList.forEach(id => {
            let index = this.list.findIndex(_ => _.id == id);
            if (index != -1) {
              this.list.splice(index, 1);
            }
          });
          Toast.fail("删除成功");
        } else {
          Toast.fail("删除失败");
        }
      });
    },
    deleteOne(item, index) {
      deleteOne({ cartIds: String(item.id) }).then(res => {
        if (res.status == 200) {
          // this.list.splice(index, 1);
          this.cartData();
          Toast.fail("删除成功");
        } else {
          Toast.fail("删除失败");
        }
      });
    },

    // 选中单个商品
    ischeck(item, pro) {
      // 为未选中的时候改变为false，反之为true
      !pro.isChecked ? this._checkTrue(item, pro) : this._checkFalse(item, pro);
    },
    // 修改单个商品的true
    _checkTrue(item, pro) {
      pro.isChecked = true; // 将商品选中状态改为true
      // 这里执行了两部，选中商品数量先+1，再与该店铺商品数量比较，如果相等就更改店铺选中状态为true
      if (++item.checkedCount === item.productList.length) {
        item.checked = true;
      } else {
        return "";
      }
      // ++item.checkedCount === item.productList.length ? item.checked = true : ''
      // 如果店铺选中状态改为true，选中店铺数量先+1，再与店铺数量比较，如果相等就更改全选选中状态为true
      // // 当商店全选状态，每全选一个商店，被选中商店数加一，数值等于所有商店数，全选状态为true
      if (item.checked) {
        if (++this.allShops === this.cartInfoList.length) {
          this.isCheckAll = true;
        } else {
          this.isCheckAll = false;
        }
      } else {
        return "";
      }
    },
    // 修改单个商品的 false
    _checkFalse(item, pro) {
      pro.isChecked = false; // 将商品选中状态改为false
      --item.checkedCount; // 被选中的商品数减一
      if (item.checked) {
        // 如果店铺是被选中的，更改店铺选中状态
        item.checked = false; // 当商店状态为选中时改变false
        --this.allShops; // 商店数减一
      }
      this.isCheckAll = false; // 全选状态为false
    },
    // 选择整个商店的商品
    checkShop() {
      this.checked = !this.checked;
      for (const item of this.saleList) {
        this.$set(item, "isChecked", this.checked);
      }
      // if (item.checked !== true) {
      //   this.$set(item, "isChecked", true);
      // } else {
      //   this.$set(item, "isChecked", false);
      // }
      // for (const el of this.saleList) {
      //   if (item.checked) {
      //     this.$set(el, "isChecked", true);
      //   } else {
      //     this.$set(el, "isChecked", false);
      //   }
      // }
      // !item.checked ? this._shopTrue(item) : this._shopFalse(item);
    },
    checkShopTomorrow() {
      this.tomorrowChecked = !this.tomorrowChecked;
      for (const item of this.tomorrowSaleList) {
        this.$set(item, "isChecked", this.tomorrowChecked);
      }
    },
    // 遍历商店每个商品,状态为false的改变为true,又在_checkTrue()方法中将商店状态改为true
    _shopTrue(item) {
      (item.productList || []).forEach(pro => {
        if (pro.isChecked === false) {
          this._checkTrue(item, pro);
        } else {
          return "";
        }
        // pro.isChecked === false ? this._checkTrue(item, pro) : ''
      });
    },
    _shopFalse(item) {
      (item.productList || []).forEach(pro => {
        // 同上
        if (pro.isChecked === true) {
          this._checkFalse(item, pro);
        } else {
          return "";
        }
        // pro.isChecked === true ? this._checkFalse(item, pro) : ''
      });
    },
    // 选择全部商店的商品，已经计算总价和总数量的函数
    checkAll(val) {
      // 方法内调用方法
      // console.log(this.isCheckAll);
      console.log(this.list);
      this.list.forEach((item, index) => {
        this.$set(this.list[index], "isChecked", this.isCheckAll);
      });
      this.$forceUpdate();
      // this.list.reduce()
      // this.isCheckAll ? this.cartInfoList.forEach(item => {
      //     this._shopTrue(item)
      // }) : this.cartInfoList.forEach(item => {
      //     this._shopFalse(item)
      // })
    },
    _totalPeice() {
      // 每次调用此方法，将初始值为0，遍历价格并累加
      this.allPrice = 0;
      this.cartInfoList.forEach(item => {
        let products = item.productList;
        products.forEach(pros => {
          if (pros.isChecked) {
            this.allPrice += pros.price * pros.count;
          }
        });
      });
    },
    _totalCount() {
      // 同上
      this.allCount = 0;
      this.cartInfoList.forEach(item => {
        this.allCount += item.checkedCount;
      });
    },
    onSubmit() {},
    onChange(value) {
      Toast.loading({ forbidClick: true });
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        Toast.clear();
        // 注意此时修改 value 后会再次触发 change 事件
        this.value = value;
      }, 500);
    },
    toPage() {
      this.show = true;
    },
    stepperChange(data) {
      let req = {
        quantity: data.quantity,
        specIds: data.specIds,
        price: data.price,
        cartId: data.id
      };
      cartUpdate(req).then(res => {
        // this.cartData();
      });
    },
    cartData() {
      cartGet().then(res => {
        this.list = res.data.vos;
        this.list.forEach(item => {
          // 当商品状态为 [PUT] 时为上架
          if ("PUT" === item.spu.status) {
            // 预售时间 [item.spu.stockingTime]
            // 送达类型(自定义业务字段) [item.sendType] {0: 当日送达, 1: 次日送达, 2: 已售罄}
            switch (item.spu.stockingTime) {
              case 1:
              case 2:
                // 当预售时间为 [1] 天或 [2] 天时, 且当前商品的SKU库存大于购买数量时
                if (item.sku.quote.repertory >= item.quantity) {
                  item.sendType = 0;
                } else {
                  item.sendType = 1;
                }
                break;
              case 30:
                // 当预售时间为 [30] 天时, 且当前商品的SKU库存小于购买数量时
                if (item.sku.quote.repertory < item.quantity) {
                  item.sendType = 2;
                } else {
                  item.sendType = 0;
                }
                break;
            }
          } else if ("DOWN" === item.spu.status) {
            item.status = "DISABLE";
          }
          //  else {
          //   item.sendType = 2;
          // }
        });
      });
    }
  },
  watch: {
    // 深度监听所有数据，每次改变重新计算总价和总数
    cartInfoList: {
      deep: true,
      handler(val, oldval) {
        this._totalPeice();
        this._totalCount();
      }
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .bottom_view {
    max-width: 538px;
  }

  .delete-button {
    visibility: hidden;
  }
}

@spacing-size: 0.29rem;
.cart-main {
  .st-item-header {
    justify-content: flex-start;

    .theme-checkbox {
      margin-right: @spacing-size;
    }
  }

  .item-list {
    padding-left: 0.77rem;
    position: relative;

    .theme-checkbox {
      margin-top: -0.24rem;
      position: absolute;
      left: 0;
      top: 50%;
    }
  }
}

.bottom_view {
  width: 100%;
  height: 57px;
  background: white;
  border: 1px solid #ebebeb;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 50px;

  .checkbox_view {
    margin-left: 12px;
    font-size: 16px;
    color: #3f3f3f;
  }

  .bottom_right_view {
    display: flex;
    flex-direction: row;
    align-items: center;

    span:nth-child(1) {
      font-size: 11px;
      color: #b0b0b0;
      margin-right: 3px;
    }

    span:nth-child(2) {
      font-size: 14px;
      color: #3f3f3f;
    }

    span:nth-child(3) {
      font-size: 12px;
      color: #3f3f3f;
      margin-top: 5px;
    }

    span:nth-child(4) {
      font-size: 18px;
      color: #3f3f3f;
      margin-right: 10px;
    }

    .jiesuan_view {
      width: 90px;
      height: 36px;
      background: #acacac;
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      margin-right: 10px;
      font-size: 13px;
      color: white;
    }

    .jiesuan_view2 {
      width: 90px;
      height: 36px;
      background: rgb(0, 176, 90);
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      margin-right: 10px;
      font-size: 13px;
      color: #fff;
    }
  }
}

.sx_img_view {
  width: 100%;
  height: 100%;
  // height: 120px;
  display: flex;
  flex-direction: row;
  background: white;
  align-items: center;

  .img_view {
    width: 81px;
    height: 81px;
    margin-left: 21px;
    margin-right: 10px;
    position: relative;

    img:first-of-type {
      width: 81px;
      height: 81px;
      border-radius: 50%;
    }

    img:last-of-type {
      width: 67px;
      height: 67px;
      position: absolute;
      top: 7px;
      left: 7px;
    }
  }
}
</style>
